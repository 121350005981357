<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="$t('avance.ajoutAvance')"
      :sub-title="$t('avance.ajoutAvanceSubTitle')"
      class="card"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="row">
      <div class="col-md-7 mt-4">
        <div class="card">
          <div class="card-body">
            <a-form :form="form" class="card-header p-5" @submit="addAvance">
              <div class="row">
                <div class="col-md-6">
                  <a-form-item>
                    <a-select
                      show-search
                      style="width: 100%"
                      option-filter-prop="children"
                      :placeholder="$t('recette.selectionnerParent')"
                      :loading="loadingParents"
                      :filter-option="filterOption"
                      :disabled="this.$route.query.parent"
                      allow-clear
                      @select="selectParent"
                      v-decorator="[
                        'parent',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('requis.selection'),
                            },
                          ],
                        },
                      ]"
                    >
                      <a-select-option
                        :value="item._id"
                        v-for="item in parentList"
                        :key="item._id"
                      >
                        {{ item.father.lastName + " " + item.father.firstName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item>
                    <a-select
                      @select="selectreceiptType"
                      v-decorator="[
                        'receiptType',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('recette.modePaiement'),
                            },
                          ],
                        },
                      ]"
                      show-search
                      :placeholder="$t('comptabilite.typeRecette')"
                      option-filter-prop="children"
                      style="width: 100%"
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="item in receiptTypes"
                        :key="item._id"
                        :value="item._id"
                      >
                        {{
                          item.name == "espéce"
                            ? $t("recette.espece")
                            : item.name == "chèque"
                            ? $t("recette.cheque")
                            : item.name == "Chèque et espéce"
                            ? $t("recette.chequeEtEspece")
                            : item.name
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
              <div class="row" v-if="modeName === 'chèque'">
                <div class="col-md-12">
                  <a-divider>{{ $t("avance.saisirChequeInfo") }}</a-divider>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.numero') + ':'">
                    <a-input
                      v-decorator="[
                        'cheque.num',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('avance.chequeNumRequis'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.dateEcheance') + ':'">
                    <a-date-picker v-decorator="['cheque.dateEchance']" />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.banque') + ':'">
                    <a-input v-decorator="['cheque.bank']" />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.titulaire') + ':'">
                    <a-input v-decorator="['cheque.titulaire']" />
                  </a-form-item>
                </div>
                <div class="col-md-12">
                  <a-form-item :label="$t('recette.montantCheque') + ':'">
                    <a-input-number
                      style="width: 100%"
                      v-decorator="[
                        'montantCheque',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: 'Un élève au moins est requis',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <!--Chèque et espéce-->
              <div class="row" v-else-if="modeName === 'Chèque et espéce'">
                <div class="col-md-12">
                  <a-divider>{{
                    $t("avance.saisirChequeEspaceInfo")
                  }}</a-divider>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.numero') + ':'">
                    <a-input
                      v-decorator="[
                        'cheque.num',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('avance.chequeNumRequis'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.dateEcheance') + ':'">
                    <a-date-picker v-decorator="['cheque.dateEchance']" />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.banque') + ':'">
                    <a-input v-decorator="['cheque.bank']" />
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item :label="$t('recette.titulaire') + ':'">
                    <a-input v-decorator="['cheque.titulaire']" />
                  </a-form-item>
                </div>
                <!--les montant-->
                <div class="col-md-6">
                  <a-form-item :label="$t('recette.montantCheque') + ':'">
                    <a-input-number
                      style="width: 100%"
                      v-decorator="[
                        'montantCheque',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.montant'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item :label="$t('recette.montantEspece')">
                    <a-input-number
                      style="width: 100%"
                      v-decorator="[
                        'montantEspece',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.montant'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <!--espéce-->
              <div class="row" v-else>
                <div class="col-md-12">
                  <a-form-item :label="$t('recette.montant')">
                    <a-input-number
                      style="width: 100%"
                      v-decorator="[
                        'montantEspece',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.montant'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <a-button
                    type="primary"
                    style="width: 100%"
                    htmlType="submit"
                    :loading="loadingClasse"
                    :disabled="loadingClasse"
                    icon="tags"
                    :ghost="false"
                  >
                    {{ $t("action.ajouter") }}
                  </a-button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
      <div class="col-md-5 mt-4">
        <h2 class="badge-example">{{ $t("avance.historiqueAvances") }}</h2>

        <div class="card">
          <div class="card-body">
            <a-table
              rowKey="_id"
              :pagination="true"
              :data-source="data"
              :columns="columns"
              :loading="tableLoading"
              :scroll="{ x: 'max-content' }"
            >
              <template slot="date" slot-scope="text">
                {{ moment(text).format("DD/MM/YYYY") }}
              </template>

              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`chercher ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("recette.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>{{ text }}</template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { PageHeader, Descriptions } from "ant-design-vue";
import apiClient from "@/services/axios";
import paymentValidator from "@/views/eleves/profile/components/paymentValidator";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";

export default {
  name: "addPayment",
  components: {
    "a-page-header": PageHeader,
    "payment-validator": paymentValidator,
    "a-descriptions": Descriptions,
  },
  async created() {
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    apiClient
      .post("/parents/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $project: {
              father: 1,
              mother: 1,
              photo: 1,
            },
          },
        ],
      })
      .then((res) => {
        this.parentList = res.data;
        if (this.$route.query.parent) {
          this.form.setFields({
            parent: {
              value: this.$route.query.parent,
            },
          });
          this.selectParent(this.$route.query.parent);
        }
      })
      .catch((e) => this.$message.warning(this.$t("warning.connectionProblem")))
      .finally(() => (this.loadingParents = false));
  },
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "addAvance" }),
      loadingParents: true,
      selectedParent: {},
      modalData: {},
      visableModal: false,
      parentList: [],
      receiptTypes: [],
      Active_studentList: [],
      reloadCount: 0,
      loadingClasse: false,
      modeName: "",
      data: [],
      rowData: [],
      cacheData: [],
      columns: [
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "receiptType.name",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) => {
            console.log(value, record);
            if (record.cheque) {
              return (
                record.receiptType.name
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return record.receiptType.name
                .toLowerCase()
                .includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        /* {
          title: "Montant espéce",
          dataIndex: "montantEspece",
          key: "montantEspece",
          sorter: (a, b) => a.montantEspece - b.montantEspece,
        },
        {
          title: "Montant chèque",
          dataIndex: "montantCheque",
          key: "montantCheque",
          sorter: (a, b) => a.montantCheque - b.montantCheque,
        },*/
        {
          title: this.$t("avance.montantAvance"),
          dataIndex: "totalAvance",
          key: "totalAvance",
          sorter: (a, b) => a.totalAvance - b.totalAvance,
        },
        {
          title: this.$t("punition.dateAjout"),
          dataIndex: "createdAt",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            record.date.includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      tableLoading: false,
      filtredTable: [],
    };
  },
  methods: {
    moment,
    addAvance(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          console.log(values);
          apiClient
            .put("/avance", values)
            .then((res) => {
              this.printOne(res.data, values);
              this.$message.success(this.$t("avance.avanceAjoute"));
              this.$router.go(-1);
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(e.response.data.message);
            })
            .finally(() => {
              setTimeout(() => (this.loadingClasse = false), 500);
            });
        }
      });
      //this.form.resetFields();
    },
    printOne(record, formValues) {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let parent = this.parentList.find((e) => e._id == this.selectedParent);
      var titre = "Reçu d'avance";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(17);
      let clientName =
        parent.father.lastName +
        " " +
        parent.father.firstName +
        doc.text(80, 60, titre);
      doc.setFontSize(16);

      doc.text(20, 70, "Nom et Prénom : " + clientName);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      const array = [];

      var arr = [];
      let colorFill = record.used ? [255, 204, 204] : [255, 255, 255];
      arr.push({
        content: clientName,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: this.modeName,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: moment(record.createdAt).format("DD/MM/YYYY"),
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: formValues.cheque ? formValues.cheque.num : "--",
        styles: { fillColor: colorFill },
      });

      arr.push({
        content: record.montantCheque,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: record.montantEspece,
        styles: { fillColor: colorFill },
      });

      arr.push({
        content: record.totalAvance,
        styles: { fillColor: colorFill },
      });

      array.push(arr);

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Nom client",
            "Mode paiement",
            "Date d'ajout",
            "Numéro du chèque",
            "Montant chèque",
            "Montant espéce",
            "Montant avance",
          ],
        ],
        body: array,
      });
      doc.save(titre + ".pdf");
    },
    cancelModel() {
      this.visableModal = false;
      this.reloadCount++;
    },
    reloadComp() {
      this.$emit("reload");
    },
    async selectParent(val) {
      this.tableLoading = true;
      this.selectedParent = val;
      console.log(val);
      await apiClient
        .get("/avance/" + val)
        .then((res) => {
          this.data = res.data;
          this.rowData = res.data;
          this.filtredTable = res.data;
        })
        .catch((e) => {
          console.error(e.stack);
          this.$message.warning(this.$t("warning.connectionProblem"));
        })
        .finally(() => (this.tableLoading = false));
    },
    selectreceiptType(val) {
      let receiptTypeName = this.receiptTypes.find((e) => e._id == val).name;
      console.log(receiptTypeName);
      this.modeName = receiptTypeName;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
  },
};
</script>
